import React, { useState, useReducer } from 'react';
import ReactCardFlip from 'react-card-flip';
import '../css/card.css';
import {
    IMnemonicCardVertex,
    MNEMONIC_NODE_TYPE_ID_CARD,
} from '@netherworlds/cm.domains.all';
import { useDispatch } from 'react-redux';
import { addCard } from '../redux/Actions';

const AddCard: React.FC = () => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [card, setCard] = useState<IMnemonicCardVertex>({
        definition: '',
        term: '',
        _typeId: MNEMONIC_NODE_TYPE_ID_CARD,
    });
    const dispatch = useDispatch();

    const handleClick = (e) => {
        e.preventDefault();
        setIsFlipped(!isFlipped);
    };

    const save = (e) => {
        e.preventDefault();
        dispatch(addCard(card));
        setIsFlipped(!isFlipped);
        setCard({
            definition: '',
            term: '',
            _typeId: MNEMONIC_NODE_TYPE_ID_CARD,
        });
    };

    function handleChange(e) {
        card[e.target.id] = e.target.value;
        setCard({ ...card });
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div className="card-group">
                <div className="card">
                    <div className="card-body w-100">
                        <a
                            onClick={handleClick}
                            href="#"
                            className="btn btn-primary btn-full"
                        >
                            +
                        </a>
                    </div>
                </div>
            </div>

            <div>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body w-100">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                    >
                                        @
                                    </span>
                                </div>
                                <input
                                    id="term"
                                    onChange={handleChange}
                                    value={card.term}
                                    type="text"
                                    className="form-control"
                                    placeholder="Deine Frage"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                ></input>
                            </div>
                            <div className="form-group">
                                <textarea
                                    id="definition"
                                    onChange={handleChange}
                                    value={card.definition}
                                    placeholder="Deine Antwort"
                                    className="form-control"
                                ></textarea>
                            </div>
                            <a
                                onClick={save}
                                href="#"
                                className="btn btn-primary"
                            >
                                Anlegen
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ReactCardFlip>
    );
};

export default AddCard;
