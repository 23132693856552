import axios from 'axios';

export function configureDefaults() {
    axios.defaults.baseURL =
        'https://8b7l0cefba.execute-api.eu-central-1.amazonaws.com/dev/';

    axios.interceptors.request.use(async (config) => {
        try {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    userId: 'dkx80m1',
                },
            };
        } catch (e) {
            console.log(e);
            return config;
        }
    });
}
