import RequestState from '../models/RequestState';
import {
    GET_CARD_SUCCESS as SUCCESS,
    UPSERT_CARD_ERROR as UpsertError,
} from './Actions';
import ApplicationState from '../models/ApplicationState';

export const GET_CARD_START = 'GET_CARD_START';

export const GET_CARD_ERROR = 'GET_CARD_ERROR';

export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';

export const ADD_CARD_START = 'ADD_CARD_START';

export const UPSERT_CARD_ERROR = 'UPSERT_CARD_ERROR';

const initialState: ApplicationState = {
    cardRequestState: RequestState.INITIAL,
    cards: [],
};

export default function reducer(
    state: ApplicationState = initialState,
    action
) {
    switch (action.type) {
        case GET_CARD_START: {
            return {
                ...state,
                cardRequestState: RequestState.FETCHING,
            };
        }
        case GET_CARD_SUCCESS: {
            const successAction = action as SUCCESS;
            return {
                ...state,
                cards: successAction.cards,
                cardRequestState: RequestState.SUCCESS,
            };
        }
        case GET_CARD_ERROR: {
            return {
                ...state,
                cardRequestState: RequestState.ERROR,
            };
        }
        case UPSERT_CARD_ERROR: {
            const upsertAction = action as UpsertError;
            return {
                ...state,
                cards: [...state.cards, upsertAction.card],
                cardRequestState: RequestState.ERROR,
            };
        }
        default:
            return state;
    }
}
