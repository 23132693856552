import ApplicationState from '../../models/ApplicationState';
import RequestState from '../../models/RequestState';

export function seletCardRequestState(state: ApplicationState): RequestState {
    return state?.cardRequestState;
}

export function seletCards(state: ApplicationState) {
    return state?.cards;
}
