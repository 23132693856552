import {
    GET_CARD_START,
    GET_CARD_SUCCESS,
    GET_CARD_ERROR,
    ADD_CARD_START,
    UPSERT_CARD_ERROR,
} from './Reducer';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import ApplicationState from '../models/ApplicationState';
import CardService from '../service/CardService';
import { IMnemonicCardVertex } from '@netherworlds/cm.domains.all';

export interface GET_CARD_START {
    type: typeof GET_CARD_START;
}

export interface GET_CARD_ERROR {
    type: typeof GET_CARD_ERROR;
}

export interface GET_CARD_SUCCESS {
    type: typeof GET_CARD_SUCCESS;
    cards: Array<IMnemonicCardVertex>;
}

export interface ADD_CARD_START {
    type: typeof ADD_CARD_START;
    card: IMnemonicCardVertex;
}

export interface UPSERT_CARD_ERROR {
    type: typeof UPSERT_CARD_ERROR;
    card: IMnemonicCardVertex;
}

export type ProcessActions =
    | GET_CARD_SUCCESS
    | GET_CARD_START
    | GET_CARD_ERROR
    | ADD_CARD_START
    | UPSERT_CARD_ERROR;

export function getCard(): ThunkAction<
    Promise<void>,
    ApplicationState,
    void,
    ProcessActions
> {
    return async (
        dispatch: ThunkDispatch<ApplicationState, void, ProcessActions>
    ) => {
        dispatch({ type: GET_CARD_START });
        const service = new CardService();
        try {
            const cards = await service.getCards();

            dispatch({ type: GET_CARD_SUCCESS, cards });
        } catch (e) {
            console.log(e);
            dispatch({ type: GET_CARD_ERROR });
        }
    };
}

export function addCard(
    card: IMnemonicCardVertex
): ThunkAction<Promise<void>, ApplicationState, void, ProcessActions> {
    return async (
        dispatch: ThunkDispatch<ApplicationState, void, ProcessActions>
    ) => {
        dispatch({ type: GET_CARD_START });
        const service = new CardService();
        try {
            const upsertedCard = await service.upsert(card);

            dispatch({ type: ADD_CARD_START, card: upsertedCard });
        } catch (e) {
            console.log(e);
            dispatch({ type: UPSERT_CARD_ERROR, card });
        }
    };
}
