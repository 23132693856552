import axios from 'axios';
import {
    IMnemonicCardVertex,
    MNEMONIC_NODE_TYPE_ID_CARD,
    COMMON_NODE_TYPE_IS_ELEMENT_OF,
    IMnemonicSetVertex,
} from '@netherworlds/cm.domains.all';
import { IEdge } from '@netherworlds/cm.foundation';

export default class CardService {
    async upsert(card: IMnemonicCardVertex) {
        const response = await axios.post<IMnemonicCardVertex>(
            '/synaptic/crud',
            card
        );
        return response.data;
    }

    async getCards() {
        const request: IGraphRequest = {
            initial: { _typeId: MNEMONIC_NODE_TYPE_ID_CARD },
            elements: [],
            includeInitialResultSet: true,
        };

        const response = await axios.post<Array<IMnemonicCardVertex>>(
            '/synaptic/graph',
            request
        );
        return response.data;
    }

    createSet(set: IMnemonicSetVertex) {
        const response = axios.post('/synaptic/crud', set);
        return response;
    }

    addCardToSet(edge: IEdge) {
        const response = axios.post('/synaptic/crud', edge);
        return response;
    }

    getSet(id: string) {
        const request: IGraphRequest = {
            initial: { _id: id },
            elements: [
                {
                    inc: {
                        edge: {
                            _typeId: COMMON_NODE_TYPE_IS_ELEMENT_OF,
                        },
                        target: {
                            _typeId: MNEMONIC_NODE_TYPE_ID_CARD,
                        },
                    },
                },
            ],
            includeInitialResultSet: true,
        };

        const response = axios.post('/synaptic/graph', request);
        return response;
    }
}

interface IGraphRequest {
    initial: { [property: string]: any }; // first level query
    elements?: IGraphQueryElement[]; // graph elements originating form the result collection of the first level query
    includeInitialResultSet?: boolean; // default: true, if false the result set will not contain the first level elements
}

interface IGraphQueryElement {
    inc?: {
        edge?: { [key: string]: any };
        target?: { [key: string]: any };
    };
    out?: {
        edge?: { [key: string]: any };
        target?: { [key: string]: any };
    };
    rep?: number | 'recurring';
}
