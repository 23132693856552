import React from 'react';
import { Provider } from 'react-redux';
import CardService from './service/CardService';
import {
    IMnemonicCardVertex,
    MNEMONIC_NODE_TYPE_ID_CARD,
    IMnemonicSetVertex,
    MNEMONIC_NODE_TYPE_ID_SET,
    COMMON_NODE_TYPE_IS_ELEMENT_OF,
} from '@netherworlds/cm.domains.all';
import CardView from './component/CardView';
import { store } from './redux/Store';
import { IEdge } from '@netherworlds/cm.foundation';

const Root = () => {
    const componentDidCatch = () => {
        console.error('error');
    };

    const add = () => {
        const a = new CardService();
        const cardVertex: IMnemonicCardVertex = {
            _typeId: MNEMONIC_NODE_TYPE_ID_CARD,
            term: 'yolo',
            definition: 'yala',
        };
        a.upsert(cardVertex);
    };

    const get = () => {
        const a = new CardService();
        a.getCards();
    };

    const createSet = () => {
        const a = new CardService();

        const set: IMnemonicSetVertex = {
            _typeId: MNEMONIC_NODE_TYPE_ID_SET,
            description: 'my Set',
            name: 'KLUG',
        };
        a.createSet(set);
    };

    const addCardToSet = () => {
        const a = new CardService();

        const edge: IEdge = {
            _typeId: COMMON_NODE_TYPE_IS_ELEMENT_OF,
            _from: { _id: '5edcfff894dfb1000843bb61' },
            _to: { _id: '5edd012094dfb1000843bb62' },
        };
        a.addCardToSet(edge);
    };

    const readSet = () => {
        const a = new CardService();

        a.getSet('5edd012094dfb1000843bb62');
    };

    return (
        <Provider store={store}>
            <CardView></CardView>
        </Provider>
    );
};

export default Root;
