import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createMemoryHistory } from 'history';
import reducer from './Reducer';

export const history = createMemoryHistory();
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(thunk, myRouterMiddleware);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(thunk, myRouterMiddleware, createLogger());
    }
};

export const store = createStore(reducer, composeWithDevTools(getMiddleware()));
