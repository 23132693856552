import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from './Card';
import {
    seletCardRequestState,
    seletCards,
} from '../redux/selector/CardSelector';
import RequestState from '../models/RequestState';
import { getCard } from '../redux/Actions';
import { IMnemonicCardVertex } from '@netherworlds/cm.domains.all';
import AddCard from './AddCard';

const CardView: React.FC = () => {
    const cardRequestState = useSelector(seletCardRequestState);
    const cards = useSelector(seletCards);

    const dispatch = useDispatch();

    if (cardRequestState === RequestState.INITIAL) {
        dispatch(getCard());
    }

    return (
        <section>
            <div className="row">
                <AddCard></AddCard>
                {cards
                    ? cards.map((card: IMnemonicCardVertex) => (
                          <div key={card._id} className="col-md-4 col-sm-12">
                              <Card card={card}></Card>
                          </div>
                      ))
                    : ''}
            </div>
        </section>
    );
};

export default CardView;
