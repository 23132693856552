import './set-public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { store } from './redux/Store';
import { configureDefaults } from './api/axiosConfig';

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    domElementGetter: createDomElement,
    // errorBoundary(err, info, props) {
    //     // Customize the root error boundary for your microfrontend here.
    //     return null;
    // },
});

export const mount = function (global) {
    configureDefaults();
    global.customProps.globalEventDistributor.registerStore(store);
    global.customProps.globalEventDistributor.dispatch(
        {
            type: 'Portal.Hide.Navigation',
            showNavigation: true,
        },
        'Portal'
    );

    return lifecycles.mount(global);
};

function createDomElement(props) {
    // Make sure there is a div for us to render into
    let el;
    if (props.customProps.contentElement) {
        el = props.customProps.contentElement;
    } else {
        el = window.document.getElementById('content');
    }

    if (!el) {
        el = window.document.createElement('learning');
        el.id = 'learning';
        window.document.body.appendChild(el);
    }

    return el;
}

export const bootstrap = lifecycles.bootstrap;
export const unmount = lifecycles.unmount;
