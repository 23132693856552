import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import '../css/card.css';
import { IMnemonicCardVertex } from '@netherworlds/cm.domains.all';

interface Prop {
    card: IMnemonicCardVertex;
}

const Card: React.FC<Prop> = ({ card }: Prop) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setIsFlipped(!isFlipped);
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div className="card-group">
                <div className="card">
                    <div className="card-body">
                        <p className="card-text">{card.term}</p>
                        <a
                            onClick={handleClick}
                            href="#"
                            className="btn btn-primary btn-full"
                        >
                            Lösung
                        </a>
                    </div>
                </div>
            </div>

            <div>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <p className="card-text">{card.definition}</p>
                            <a
                                onClick={handleClick}
                                href="#"
                                className="btn btn-primary"
                            >
                                Frage
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ReactCardFlip>
    );
};

export default Card;
